import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from 'axios'
import update from 'immutability-helper'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, EditorState } from "draft-js";
import "./styles.css";
import Box from '@material-ui/core/Box';
import { Chip } from '@material-ui/core';

const description = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "Please write your Message",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ]
};



export default class SingleVacancy extends React.Component {
  constructor(props) {
    super(props);
    const contentState = convertFromRaw(description);
    const editorState = EditorState.createWithContent(contentState);

    this.state = {
      contentState,
      editorState,
      job: [],
      jobapplications: [],
      job_id: '',
      completename: '',
      email: '',
      resume: [],
      open: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onContentStateChange = this.onContentStateChange.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.handleChangeUpload = this.handleChangeUpload.bind(this);

  }


  handleChange (evt, field) {
    this.setState({ [field]: evt.target.value })
  }


  handleChangeUpload = (e) => {
    e.persist()
    this.setState(() => {
        return {
            [e.target.name]: e.target.files[0]
        }
    })
}


  
  onContentStateChange = contentState => {
    this.setState({
      contentState
    });
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    window.location.href = "/";
    axios.post('https://hris.mof.gov.tl/api/v1/jobapplications', {jobapplication: {
      job_id: this.state.job.id,
      completename: this.state.completename,
      email: this.state.email,
      resume: this.state.resume,
      description: this.state.description
    }, headers: {'Authorization': 'Bearer ...'}, 
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total)
    }
  })
    .then(response => {
      const jobapplications = update(this.state.jobapplications, {
        $splice: [[0, 0, response.data]]
      })
      this.setState({
        jobapplications: jobapplications,
        job_id: '',
        completename: '',
        email: '',
        resume: null,
        description: '',
        currentDateTime: Date().toLocaleString()
      })
    })
    .catch(error => console.log(error))      
  }

  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    fetch(`https://hris.mof.gov.tl/api/v1/jobs/${id}`).then((response) => response.json()).then((job) => this.setState({ job }));
  }

  render() {
    const { job} = this.state;
    const { editorState } = this.state;
    const details = job.description
    const deadline_job = job.deadline
    const  currentDate = Date().toLocaleString()
    var msDiff = new Date(deadline_job).getTime() - new Date(currentDate).getTime();    //Future date - 
    var remaining_days = Math.floor(msDiff / (1000 * 60 * 60 * 24));

    console.log(details)
    return (
      <div>
        <br/>
      <Container>
          <Dialog open={this.state.open} fullWidth={true} maxWidth='md'>
            <DialogTitle id='simple-dialog-title'>
              Application Details for {job.title}
            </DialogTitle>
           <DialogTitle id='simple-dialog-title'>
               <Chip label= {"This job is expired in " + remaining_days +  " Days"} color="primary"/>
            </DialogTitle>
             
            <DialogContent>
            <form onSubmit={this.handleSubmit}>
                <label>
                  <input type="hidden" id={job.id} value={job.id}/>
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      id="completename"
                      label="Complete Name"
                      name="completename"
                      autoComplete="completename"
                      autoFocus
                      onChange={(event)=>this.handleChange(event, "completename")}
                    />
                  <br/>
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={(event)=>this.handleChange(event, "email")}
                    />
                    <br/>
                    <div className="editorContainer">
                      <div className="RichEditor-root">
                        <Editor
                          editorClassName={"report-editor"}
                          editorState={editorState}
                          onEditorStateChange={this.onEditorStateChange}
                          onContentStateChange={this.onContentStateChange}
                        />
                      </div>
                    </div>
                  <br/>
                    <div class="upload-btn-wrapper">
                      <button class="btn">Upload Files</button>
                        <input 
                            type="file" 
                            name="resume"
                            id="resume"
                            onChange={this.handleChangeUpload}
                          />
                    </div>
                  <br/>
                </label>
                <br/>
                <Box display="flex" justifyContent="space-between">
                  <Button type="submit" size="small" color="primary" variant="outlined">Submit</Button>
                  <Button type="submit" size="small" color="secondary" onClick={() => this.setState({ open: !this.state.open })}  variant="outlined" >Cancel</Button>
                </Box>
              </form>
              </DialogContent>
          </Dialog>
        <Card>
          <CardContent>        
        <Grid container spacing={2}>
          <Grid item  xs={12}>
            <div className="topInfo">
              <Typography variant="h5" component="h2">
                  {job.title}
                </Typography>
                <Typography  color="white" gutterBottom>
                  Deadline : {job.deadline}
                </Typography>
                <Typography  color="white" gutterBottom>
                   Remaining Days : <Chip label= {remaining_days +  " Days"} color="primary"/>
                </Typography>
                 <Typography variant="body2" component="p">
                  <Button type="submit" size="small" variant="outlined" color="inherit" href="https://hris.mof.gov.tl/e-recruitment/sign_in" target="_blank">Apply this job</Button>
                </Typography>
              </div>
              <Typography  color="white" gutterBottom>
              <div dangerouslySetInnerHTML={ {__html: details && details.body} } />
              </Typography>
            </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Container>
        <br/>
      </div>
    );
  }
}
