import React from 'react'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import gifService from "./mofplan.gif"
import CardHeader from '@material-ui/core/CardHeader';

const Service = () => {
    return(
        <div>
                <Card style={{ border: "none", boxShadow: "none", borderRadius:"0%", background: '#007c7c', justifyItems: "center"}}>
                <CardHeader
                    title="Programa Ministeriu das Finansas"
                    subheader="Programa ba Ministeriu das Finansas iha Tinan ida ne'e"
                    justifyItems="center"
                />
                    <CardMedia style={{height: 0, paddingTop: '35%'}}
                    image={gifService}
                    />
                </Card>
        </div>
    )
}
export default Service
